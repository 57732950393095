<template>
  <div>
    <Card>
      <template #card-body>
        <div class="mt-3">
          <b-button
            variant="primary-custom"
            @click="$router.push(`/contas_bancarias/${unityId}/adicionar`)"
            >Adicionar</b-button
          >
        </div>
        <b-table class="mt-3" hover :fields="fields" :items="data">
          <template #cell(id)="data">
            <b-dropdown
              class="p-0 m-0 d-flex"
              size="md"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <fa-icon size="1x" icon="bars" />
              </template>
              <b-dropdown-item
                @click="
                  $router.push(
                    `/contas_bancarias/${unityId}/editar/${data.item.id}`
                  )
                "
                title="Detalhes"
              >
                Editar</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      unityId: this.$route.params.unityId,
      unity_details: {},
      fields: [
        {
          key: "id",
          label: "#",
          thStyle: {
            width: "5%",
            textAlign: "center",
          },
          thClass: "text-muted small",
          tdClass: "p-0 text-muted text-center pt-1",
        },
        {
          key: "bankDetail.description",
          label: "Banco",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
        {
          key: "account_type",
          label: "Tipo de Conta",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
        {
          key: "agency",
          label: "Agência",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
        {
          key: "account",
          label: "Nº da Conta",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
        {
          key: "description",
          label: "Descrição",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
      ],
    };
  },
};
</script>

<style></style>
